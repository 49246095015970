import React from 'react';

import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import Logo from 'components/icons/logo';
import Footer from 'components/common/footer';
import theme from 'config/theme';
import { Divider } from 'lib/Divider';
import routes from 'config/routes';

const { colors } = theme;

const VerificationsDisclaimer = () => (
	<>
		<Helmet>
			<title>Verifications Disclaimer</title>
			<meta name="description" content="verifications" />
		</Helmet>
		<StaticHeader>
			<Logo color={colors.blue} />
		</StaticHeader>
		<Title>Verifications</Title>
		<ContentContainer>
			<Header>What are verifications?</Header>
			<Paragraph>
				Your safety and security on the DigsConnect platform are our top priority, and as such we
				have partnered with colleges and institutions around South Africa to provide a variety of
				options for their students.
			</Paragraph>
			<Paragraph>
				There are select properties that are marked as verified by a variety of colleges and
				institutions within South Africa. DigsConnect is made aware of these verifications directly
				from the properties’ associated owners and/or their duly appointed legal representatives
				and/or the college or institution from which the verification was received.{' '}
			</Paragraph>
			<Paragraph>
				These properties have been visited by the respective colleges and institutions and deemed
				suitablefor their students based on their own internal criteria, including but not limited
				to the property location, security, facilities and value.
			</Paragraph>
			<Paragraph>
				All verifications are for properties whose owners and/or legal representatives have agreed
				to the DigsConnect{' '}
				<Link href={routes.privacy} target="_blank">
					Privacy Policy
				</Link>{' '}
				and{' '}
				<Link href={routes.terms_and_conditions} target="_blank">
					Terms of Use
				</Link>
				.
			</Paragraph>
			<Divider />
			<Header>How does it work?</Header>
			<Paragraph>
				The verified properties listed on the platform that contain the logo of a particular
				institution or collegemeet the minimum standards as set out by the college or institution
				associated to the property. Neither the college nor institution partnered with DigsConnect
				shall be liable for any damages as set out above.
			</Paragraph>
			<Paragraph>
				DigsConnect does not visit all properties listed on the platform. Accordingly, DigsConnect
				does not warrant and is not responsible for the use, quality, and conditions of the
				properties listed on our platform. We do not make any representations, warranties, or
				conditions of any kind whether implied, statutory or otherwise. We encourage all users to do
				their own due diligence before agreeing to any terms with regards to these properties.
			</Paragraph>
			<Paragraph>
				You acknowledge and agree that it is your sole responsibility to ensure the use, quality,
				and conditions of the properties prior to agreeing to any terms associated thereto.
			</Paragraph>
			<Paragraph>
				Should you become aware of any properties with falsified information or that, in your
				opinion, any properties no longer meet the minimum requirements as set out by the associated
				college or institution then please immediately contact us at{' '}
				<a href="mailto:greg@digsconnect.com">Greg@DigsConnect.com</a>
			</Paragraph>
		</ContentContainer>
		<Footer />
	</>
);

export default VerificationsDisclaimer;

const StaticHeader = styled.div`
	display: flex;
	height: 80px;
	align-items: center;
	padding: 0 16px;
`;

const Title = styled.h1`
	background: ${colors.blue};
	color: ${colors.white};
	text-align: center;
	font-weight: bold;
	font-size: 2em;
	padding: 24px 40px;
	line-height: 1.4;
`;

const ContentContainer = styled.div`
	margin: auto;
	margin-top: 24px;
	margin-bottom: 48px;
	padding: 0 16px;
	max-width: 1020px;
`;

const Paragraph = styled.p`
	margin-bottom: 16px;
`;

const Header = styled.h2`
	font-weight: 600;
	font-size: 20px;
	margin-bottom: 16px;
`;

const Link = styled.a`
	color: ${colors.pink};
`;
